import { gql } from "@apollo/client";

import {
  listenToAddZone,
  listenToDeleteZone,
  listenToDeviceHealthData,
  listenToEdgeClientMessage,
  listenToHumanValidationEvents,
  listenToNodeFiles,
  listenToNodeOnlineStatus,
  listenToNodeMessages,
  listenToServiceHealthData,
  listenToUpdateZone,
} from "../../graphql/subscriptions";

export const LISTEN_TO_HUMAN_VALIDATION_EVENTS = gql`
  ${listenToHumanValidationEvents}
`;

export const LISTEN_TO_NODE_FILES = gql`
  ${listenToNodeFiles}
`;

export const LISTEN_TO_NODE_MESSAGES = gql`
  ${listenToNodeMessages}
`;

export const LISTEN_TO_DEVICE_HEALTH_DATA = gql`
  ${listenToDeviceHealthData}
`;

export const LISTEN_TO_NODE_ONLINE_STATUS = gql`
  ${listenToNodeOnlineStatus}
`;

export const LISTEN_TO_SERVICE_HEALTH_DATA = gql`
  ${listenToServiceHealthData}
`;

export const LISTEN_TO_EDGE = gql`
  ${listenToEdgeClientMessage}
`;

export const LISTEN_UPDATE_ZONE = gql`
  ${listenToUpdateZone}
`;

export const LISTEN_ADD_ZONE = gql`
  ${listenToAddZone}
`;

export const LISTEN_DELETE_ZONE = gql`
  ${listenToDeleteZone}
`;
