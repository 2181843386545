import { useSubscription } from "@apollo/client";

import type { ListenToDeviceHealthDataSubscription } from "../../../API";
import { LISTEN_TO_DEVICE_HEALTH_DATA } from "../../../common/operations/subscriptions";
import { useCustomerIdGuard } from "../../../common/hooks/useCustomerIdGuard";
import { isNetworkHealthOnline } from "../../../common/helpers/isNetworkHealthOnline";

export const useListenToDeviceHealthDataChange = () => {
  const customerId = useCustomerIdGuard();

  const { data, loading, error } =
    useSubscription<ListenToDeviceHealthDataSubscription>(
      LISTEN_TO_DEVICE_HEALTH_DATA,
      {
        fetchPolicy: "no-cache",
        variables: {
          customerId,
        },
      }
    );

  let isOnline = false;

  try {
    if (data?.listenToDeviceHealthData?.healthData) {
      const deviceHealthData = data.listenToDeviceHealthData.healthData;

      isOnline = deviceHealthData?.isOnline
        ? deviceHealthData.isOnline
        : isNetworkHealthOnline(+data.listenToDeviceHealthData.healthData);
    }
  } catch (err) {
    console.error("Failed to parse device health data JSON:", err);
  }

  return { data, loading, error, isOnline };
};
