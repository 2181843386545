import { useSubscription } from "@apollo/client";

import type { ListenToNodeOnlineStatusSubscription } from "../../../API";
import { LISTEN_TO_NODE_ONLINE_STATUS } from "../../../common/operations/subscriptions";
import { useCustomerIdGuard } from "../../../common/hooks/useCustomerIdGuard";

export const useListenToNodeOnlineStatusChange = () => {
  const customerId = useCustomerIdGuard();

  const { data, loading, error } =
    useSubscription<ListenToNodeOnlineStatusSubscription>(
      LISTEN_TO_NODE_ONLINE_STATUS,
      {
        fetchPolicy: "no-cache",
        variables: {
          customerId,
        },
      }
    );

  return { data, loading, error };
};
