import { useAuthenticator } from "@aws-amplify/ui-react";
import { RoleEnum } from "../models/enums";

export const useCheckForRoot = () => {
  // NOTE: To prevent undesired re-renders, you can pass a function to useAuthenticator that takes
  // in Authenticator context and returns an array of desired context values.
  // The hook will only trigger re-render if any of the array values change.
  // https://ui.docs.amplify.aws/react/connected-components/authenticator/advanced#prevent-re-renders
  const { user } = useAuthenticator(context => [
    context.user,
    context.route,
    context.authStatus,
  ]);

  const payload = user?.getSignInUserSession()?.getIdToken()?.payload;

  const role = payload?.role?.replace("_", "#");

  const allowedRoles = [
    RoleEnum.ROOT,
    RoleEnum.PARTNER_ADMIN,
    RoleEnum.CUSTOMER_ADMIN,
  ];

  return allowedRoles.includes(role);
};
