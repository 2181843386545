/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { lazy, Suspense } from "react";
import { Route, Routes } from "react-router-dom";

import FullPageLoader from "../common/components/item/FullPageLoader";
import { useAuthenticatedUser } from "../common/hooks/useAuthenticatedUser";
import { AmplifyAuthStatus } from "../common/models/amplify";
import { RoleEnum, RouteEnum } from "../common/models/enums";
import ClientUploadsPage from "../leak-finder/ClientUploadsPage";
import AddNewClientPage from "../leak-finder/components/AddNewClientPage";
import EditClientPage from "../leak-finder/components/EditClientPage";
import EditGroupPage from "../leak-finder/components/EditGroupPage";
import { UploadVideoGroupPage } from "../leak-finder/pages/UploadVideoGroupPage";
import LeakFinderUserPreferencesPage from "../leak-finder/pages/user-preferences/LeakFinderUserPreferencesPage";
import OverviewModelPage from "../pages/devices/components/OverviewModelPage";
import LoginPage from "../pages/login/LoginPage";
import MediaViewerPage from "../pages/media-viewer/MediaViewerPage";
import CreatePlateNumberPage from "../pages/system-settings/tabs/plates-management/components/CreatePlateNumberPage";
import CreatePlateNumberPageSystemSettings from "../pages/system-settings/tabs/plates-management/components/CreatePlateNumberPageSystemSettings";
import PauseAlertsPage from "../pages/system-settings/tabs/plates-management/components/PauseAlertsPage";
import LeakFinderRoleBasedRoute from "./LeakFinderRoleBasedRoute";
import RoleBasedRoute from "./RoleBasedRoute";

const CustomerSettingsPage = lazy(
  () => import("../pages/customer-settings/CustomerSettingsPage")
);

const CreateAlertPage = lazy(
  () =>
    import(
      "../pages/customer-settings/tabs/alert-management/create-alert/CreateAlertPage"
    )
);

const CreateLocationPage = lazy(
  () => import("../pages/customer-settings/tabs/locations/CreateLocationPage")
);

const EditLocationPage = lazy(
  () => import("../pages/customer-settings/tabs/locations/EditLocationPage")
);

const AddModelPage = lazy(
  () => import("../pages/devices/components/AddModelPage")
);
const AppOld = lazy(() => import("../AppOld"));
const AdminLayout = lazy(
  () => import("../common/components/layout-new/AdminLayout")
);
const CustomerLayout = lazy(
  () => import("../common/components/layout-new/CustomerLayout")
);
const GeneralLayout = lazy(
  () => import("../common/components/layout/GeneralLayout")
);
const HumanValidatorLayout = lazy(
  () => import("../common/components/layout/HumanValidatorLayout")
);
const DataHubPage = lazy(() => import("../pages/data-hub/DataHubPage"));
const DataHubDashboard = lazy(
  () => import("../pages/data-hub/components/DataHubDashboard")
);
const DataHubPageContent = lazy(
  () => import("../pages/data-hub/components/DataHubPageContent")
);
const DataHubLiveViewPageContent = lazy(
  () =>
    import("../pages/data-hub/components/liveview/DataHubLiveViewPageContent")
);
const ZoneList = lazy(
  () =>
    import("../pages/data-hub/components/liveview/components/zonelist/ZoneList")
);
const DeviceDetailPage = lazy(
  () => import("../pages/devices/device-details/DeviceDetailPage")
);
const DevicesPage = lazy(() => import("../pages/devices/DevicesPage"));
const EditDevicePage = lazy(() => import("../pages/devices/EditDevicePage"));
const EditZonePage = lazy(
  () => import("../pages/devices/components/EditZonePage")
);
const ZoneOverviewPage = lazy(
  () => import("../pages/devices/components/ZoneOverviewPage")
);
const CreateDevicePage = lazy(
  () => import("../pages/devices/devices-create/CreateDevicePage")
);
const CreateZonePage = lazy(
  () => import("../pages/devices/zones-setup-page/CreateZonePage")
);
const EmailNotificationsPage = lazy(
  () => import("../pages/email-notifications/EmailNotificationsPage")
);
const HumanValidatorPage = lazy(
  () => import("../pages/human-validator/HumanValidatorPage")
);
const HvReviewPage = lazy(() => import("../pages/hv-review/HvReviewPage"));

const AzureIntegrationPage = lazy(
  () => import("../pages/integrations/components/AzureIntegrationPage")
);
const EdgeIntegrationPage = lazy(
  () => import("../pages/integrations/components/EdgeIntegrationPage")
);
const S3IntegrationPage = lazy(
  () => import("../pages/integrations/components/S3IntegrationPage")
);

// const LoginPage = lazy(() => import("../pages/login/LoginPage"));
const ModelManagerPage = lazy(
  () => import("../pages/model-manager/ModelManagerPage")
);
const CreateServicePage = lazy(
  () => import("../pages/model-manager/service-create/CreateServicePage")
);
const NodesPage = lazy(() => import("../pages/nodes/NodesPage"));
const CreateNodePage = lazy(
  () => import("../pages/nodes/nodes-create/CreateNodePage")
);
const NotAuthorizedPage = lazy(
  () => import("../pages/not-authorized-page/NotAuthorizedPage")
);
const PendingUserPage = lazy(
  () => import("../pages/pending-user-page/PendingUserPage")
);
const StatisticsPage = lazy(() => import("../pages/statistics/StatisticsPage"));
const SystemSettingsPage = lazy(
  () => import("../pages/system-settings/SystemSettingsPage")
);
const DeploymentHealthPage = lazy(
  () => import("../pages/deployment-health/DeploymentHealthPage")
);
const PlatesManagement = lazy(
  () =>
    import("../pages/system-settings/tabs/plates-management/PlatesManagement")
);

const EditPlateNumberPage = lazy(
  () =>
    import(
      "../pages/system-settings/tabs/plates-management/components/EditPlateNumberPage"
    )
);

const EditCategoryPage = lazy(
  () =>
    import(
      "../pages/system-settings/tabs/plates-management/components/plate-category/EditCategoryPage"
    )
);

const LeakFinderLayout = lazy(
  () => import("../common/components/layout/LeakFinderLayout")
);

export const homeRoute = "/devices";

const AppRoutes = (): JSX.Element => {
  const { route } = useAuthenticatedUser();

  const element =
    route === AmplifyAuthStatus.AUTHENTICATED ? (
      <GeneralLayout />
    ) : (
      <LoginPage />
    );

  return (
    <Suspense fallback={<FullPageLoader />}>
      <Routes>
        <Route path="/" element={element} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/pending-user" element={<PendingUserPage />} />
        <Route path="/unauthorized" element={<NotAuthorizedPage />} />
        <Route
          path="/products"
          element={
            <main style={{ padding: "1em" }}>
              <p>
                Please contact CleanConnect on how to become a customer to gain
                access to its large library of products!
              </p>
              <button onClick={(): void => window.location.replace("/login")}>
                Go Back To Login page
              </button>
            </main>
          }
        />
        <Route
          path="/success"
          element={
            <main style={{ padding: "1em" }}>
              <p>
                Account created successfully! Please wait for an administrator
                to approve your account.
              </p>
              <button onClick={(): void => window.location.replace("/login")}>
                Go Back To Login page
              </button>
            </main>
          }
        />

        <Route
          path={RouteEnum.MediaViewer}
          index
          element={
            <RoleBasedRoute
              allowedRoles={[
                RoleEnum.ROOT,
                RoleEnum.CC_OPS_ADMIN,
                RoleEnum.CC_OPS,
                RoleEnum.CC_SALES,
                RoleEnum.PARTNER_ADMIN,
                RoleEnum.CUSTOMER_ADMIN,
                RoleEnum.CUSTOMER_HSE,
                RoleEnum.CUSTOMER_SECURITY,
                RoleEnum.LEAKFINDER_DIRECTOR,
                RoleEnum.CUSTOMER_OPERATOR_FOREMAN,
                RoleEnum.PARTNER_USER,
                RoleEnum.CUSTOMER_OPS_TEAM,
                RoleEnum.VERIFIER,
              ]}
            >
              <MediaViewerPage />
            </RoleBasedRoute>
          }
        />

        <Route element={<AdminLayout />}>
          <Route
            path={RouteEnum.RecentActivities}
            element={
              <RoleBasedRoute
                allowedRoles={[
                  RoleEnum.ROOT,
                  RoleEnum.CC_OPS_ADMIN,
                  RoleEnum.CC_OPS,
                  RoleEnum.CC_SALES,
                  RoleEnum.PARTNER_ADMIN,
                  RoleEnum.CUSTOMER_ADMIN,
                  RoleEnum.CUSTOMER_HSE,
                  RoleEnum.CUSTOMER_SECURITY,
                ]}
              >
                <DataHubPage />
              </RoleBasedRoute>
            }
          >
            <Route
              index
              element={
                <RoleBasedRoute
                  allowedRoles={[
                    RoleEnum.ROOT,
                    RoleEnum.CC_OPS_ADMIN,
                    RoleEnum.CC_OPS,
                    RoleEnum.CC_SALES,
                    RoleEnum.PARTNER_ADMIN,
                    RoleEnum.CUSTOMER_ADMIN,
                    RoleEnum.CUSTOMER_HSE,
                    RoleEnum.CUSTOMER_SECURITY,
                  ]}
                >
                  <DataHubDashboard />
                </RoleBasedRoute>
              }
            />
            <Route
              path={RouteEnum.LiveView}
              element={
                <RoleBasedRoute
                  allowedRoles={[
                    RoleEnum.ROOT,
                    RoleEnum.CC_OPS_ADMIN,
                    RoleEnum.CC_OPS,
                    RoleEnum.CC_SALES,
                    RoleEnum.PARTNER_ADMIN,
                    RoleEnum.CUSTOMER_ADMIN,
                    RoleEnum.CUSTOMER_HSE,
                    RoleEnum.CUSTOMER_SECURITY,
                  ]}
                >
                  <DataHubLiveViewPageContent />
                </RoleBasedRoute>
              }
            />
            <Route
              path={RouteEnum.ZoneList}
              element={
                <RoleBasedRoute
                  allowedRoles={[
                    RoleEnum.ROOT,
                    RoleEnum.CC_OPS_ADMIN,
                    RoleEnum.CC_OPS,
                    RoleEnum.CC_SALES,
                    RoleEnum.PARTNER_ADMIN,
                    RoleEnum.CUSTOMER_ADMIN,
                    RoleEnum.CUSTOMER_HSE,
                    RoleEnum.CUSTOMER_SECURITY,
                  ]}
                >
                  <ZoneList />
                </RoleBasedRoute>
              }
            />
            <Route
              path={RouteEnum.CreateZone}
              element={
                <RoleBasedRoute
                  allowedRoles={[
                    RoleEnum.ROOT,
                    RoleEnum.CC_OPS_ADMIN,
                    RoleEnum.CC_OPS,
                    RoleEnum.CC_SALES,
                    RoleEnum.PARTNER_ADMIN,
                    RoleEnum.CUSTOMER_ADMIN,
                    RoleEnum.CUSTOMER_HSE,
                    RoleEnum.CUSTOMER_SECURITY,
                  ]}
                >
                  <CreateZonePage />
                </RoleBasedRoute>
              }
            />
            <Route
              path={`${RouteEnum.RecentActivities}/:model`}
              element={
                <RoleBasedRoute
                  allowedRoles={[
                    RoleEnum.ROOT,
                    RoleEnum.CC_OPS_ADMIN,
                    RoleEnum.CC_OPS,
                    RoleEnum.CC_SALES,
                    RoleEnum.PARTNER_ADMIN,
                    RoleEnum.CUSTOMER_ADMIN,
                    RoleEnum.CUSTOMER_HSE,
                    RoleEnum.CUSTOMER_SECURITY,
                  ]}
                >
                  <DataHubPageContent />
                </RoleBasedRoute>
              }
            />
          </Route>
          <Route
            path="/nodes/create"
            element={
              <RoleBasedRoute
                allowedRoles={[
                  RoleEnum.ROOT,
                  RoleEnum.CC_OPS_ADMIN,
                  RoleEnum.CC_OPS,
                  RoleEnum.CC_SALES,

                  RoleEnum.CUSTOMER_ADMIN,
                ]}
              >
                <CreateNodePage />
              </RoleBasedRoute>
            }
          />
          <Route
            path="/nodes"
            element={
              <RoleBasedRoute
                allowedRoles={[
                  RoleEnum.ROOT,
                  RoleEnum.CC_OPS_ADMIN,
                  RoleEnum.CC_OPS,
                  RoleEnum.CC_SALES,
                ]}
              >
                <NodesPage />
              </RoleBasedRoute>
            }
          />

          <Route
            path="/devices/create"
            element={
              <RoleBasedRoute
                allowedRoles={[
                  RoleEnum.ROOT,
                  RoleEnum.CC_OPS_ADMIN,
                  RoleEnum.CC_OPS,
                  RoleEnum.CC_SALES,
                  RoleEnum.PARTNER_ADMIN,
                  RoleEnum.CUSTOMER_ADMIN,
                ]}
              >
                <CreateDevicePage />
              </RoleBasedRoute>
            }
          />
          <Route
            path="/devices/zone-setup"
            element={
              <RoleBasedRoute
                allowedRoles={[
                  RoleEnum.ROOT,
                  RoleEnum.CC_OPS_ADMIN,
                  RoleEnum.CC_OPS,
                  RoleEnum.CC_SALES,
                  RoleEnum.PARTNER_ADMIN,
                  RoleEnum.CUSTOMER_ADMIN,
                ]}
              >
                <CreateZonePage />
              </RoleBasedRoute>
            }
          />

          <Route
            path="/devices/:locationId"
            element={
              <RoleBasedRoute
                allowedRoles={[
                  RoleEnum.ROOT,
                  RoleEnum.CC_OPS_ADMIN,
                  RoleEnum.CC_OPS,
                  RoleEnum.CC_SALES,
                  RoleEnum.PARTNER_ADMIN,
                  RoleEnum.CUSTOMER_ADMIN,
                ]}
              >
                <DevicesPage />
              </RoleBasedRoute>
            }
          />

          <Route
            path="/devices/:nodeId"
            element={
              <RoleBasedRoute
                allowedRoles={[
                  RoleEnum.ROOT,
                  RoleEnum.CC_OPS_ADMIN,
                  RoleEnum.CC_OPS,
                  RoleEnum.CC_SALES,
                  RoleEnum.PARTNER_ADMIN,
                  RoleEnum.CUSTOMER_ADMIN,
                ]}
              >
                <DevicesPage />
              </RoleBasedRoute>
            }
          />

          <Route
            path="/device/:deviceId/create-zone"
            element={
              <RoleBasedRoute
                allowedRoles={[
                  RoleEnum.ROOT,
                  RoleEnum.CC_OPS_ADMIN,
                  RoleEnum.CC_OPS,
                  RoleEnum.CC_SALES,
                  RoleEnum.PARTNER_ADMIN,
                  RoleEnum.CUSTOMER_ADMIN,
                ]}
              >
                <CreateZonePage />
              </RoleBasedRoute>
            }
          />

          <Route
            path="/device/:deviceId/:zoneId/edit-zone"
            element={
              <RoleBasedRoute
                allowedRoles={[
                  RoleEnum.ROOT,
                  RoleEnum.CC_OPS_ADMIN,
                  RoleEnum.CC_OPS,
                  RoleEnum.CC_SALES,
                  RoleEnum.PARTNER_ADMIN,
                  RoleEnum.CUSTOMER_ADMIN,
                ]}
              >
                <EditZonePage />
              </RoleBasedRoute>
            }
          />

          <Route
            path="/device/:deviceId/:zoneId"
            element={
              <RoleBasedRoute
                allowedRoles={[
                  RoleEnum.ROOT,
                  RoleEnum.CC_OPS_ADMIN,
                  RoleEnum.CC_OPS,
                  RoleEnum.CC_SALES,
                  RoleEnum.PARTNER_ADMIN,
                  RoleEnum.CUSTOMER_ADMIN,
                ]}
              >
                <ZoneOverviewPage />
              </RoleBasedRoute>
            }
          />

          <Route
            path="/device/:deviceId/add-model/:modelToRun"
            element={
              <RoleBasedRoute
                allowedRoles={[
                  RoleEnum.ROOT,
                  RoleEnum.CC_OPS_ADMIN,
                  RoleEnum.CC_OPS,
                  RoleEnum.CC_SALES,
                  RoleEnum.PARTNER_ADMIN,
                  RoleEnum.CUSTOMER_ADMIN,
                ]}
              >
                <AddModelPage />
              </RoleBasedRoute>
            }
          />

          <Route
            path="/device/:deviceId/model/:modelType"
            element={
              <RoleBasedRoute
                allowedRoles={[
                  RoleEnum.ROOT,
                  RoleEnum.CC_OPS_ADMIN,
                  RoleEnum.CC_OPS,
                  RoleEnum.CC_SALES,
                  RoleEnum.PARTNER_ADMIN,
                  RoleEnum.CUSTOMER_ADMIN,
                ]}
              >
                <OverviewModelPage />
              </RoleBasedRoute>
            }
          />

          <Route
            path="/device/:deviceId"
            element={
              <RoleBasedRoute
                allowedRoles={[
                  RoleEnum.ROOT,
                  RoleEnum.CC_OPS_ADMIN,
                  RoleEnum.CC_OPS,
                  RoleEnum.CC_SALES,
                  RoleEnum.PARTNER_ADMIN,
                  RoleEnum.CUSTOMER_ADMIN,
                ]}
              >
                <DeviceDetailPage />
              </RoleBasedRoute>
            }
          />
          <Route
            path="/device/edit/:deviceId"
            element={
              <RoleBasedRoute
                allowedRoles={[
                  RoleEnum.ROOT,
                  RoleEnum.CC_OPS_ADMIN,
                  RoleEnum.CC_OPS,
                  RoleEnum.CC_SALES,
                  RoleEnum.PARTNER_ADMIN,
                  RoleEnum.CUSTOMER_ADMIN,
                ]}
              >
                <EditDevicePage />
              </RoleBasedRoute>
            }
          />
          <Route
            path="/devices"
            element={
              <RoleBasedRoute
                allowedRoles={[
                  RoleEnum.ROOT,
                  RoleEnum.CC_OPS_ADMIN,
                  RoleEnum.CC_OPS,
                  RoleEnum.CC_SALES,
                  RoleEnum.PARTNER_ADMIN,
                  RoleEnum.CUSTOMER_ADMIN,
                ]}
              >
                <DevicesPage />
              </RoleBasedRoute>
            }
          />

          <Route
            path={RouteEnum.Statistics}
            element={
              <RoleBasedRoute
                allowedRoles={[
                  RoleEnum.ROOT,
                  RoleEnum.CC_OPS_ADMIN,
                  RoleEnum.CC_OPS,
                  RoleEnum.CC_SALES,
                  RoleEnum.PARTNER_ADMIN,
                  RoleEnum.CUSTOMER_ADMIN,
                  RoleEnum.CUSTOMER_OPERATOR_FOREMAN,
                  RoleEnum.PARTNER_USER,
                  RoleEnum.CUSTOMER_OPS_TEAM,
                  RoleEnum.CUSTOMER_HSE,
                  RoleEnum.CUSTOMER_SECURITY,
                ]}
              >
                <StatisticsPage />
              </RoleBasedRoute>
            }
          />

          <Route
            path="/model"
            element={
              <RoleBasedRoute
                allowedRoles={[
                  RoleEnum.ROOT,
                  RoleEnum.CC_OPS_ADMIN,
                  RoleEnum.CC_OPS,
                  RoleEnum.CC_SALES,
                  RoleEnum.PARTNER_ADMIN,
                  RoleEnum.CUSTOMER_ADMIN,
                  // RoleEnum.CUSTOMER_OPERATOR_FOREMAN, removed for now
                ]}
              >
                <ModelManagerPage />
              </RoleBasedRoute>
            }
          />
          <Route
            path="/model/create"
            element={
              <RoleBasedRoute
                allowedRoles={[
                  RoleEnum.ROOT,
                  RoleEnum.CC_OPS_ADMIN,
                  RoleEnum.CC_OPS,
                  RoleEnum.CC_SALES,
                  // RoleEnum.PARTNER_ADMIN,
                  // RoleEnum.CUSTOMER_ADMIN,
                  // RoleEnum.CUSTOMER_OPERATOR_FOREMAN, removed for now
                ]}
              >
                <CreateServicePage />
              </RoleBasedRoute>
            }
          />

          <Route
            path="/deployment-health"
            element={
              <RoleBasedRoute
                allowedRoles={[
                  RoleEnum.ROOT,
                  RoleEnum.CC_OPS_ADMIN,
                  RoleEnum.CC_OPS,
                  RoleEnum.CC_SALES,
                  RoleEnum.PARTNER_ADMIN,
                  RoleEnum.CUSTOMER_ADMIN,
                ]}
              >
                <DeploymentHealthPage />
              </RoleBasedRoute>
            }
          />

          <Route
            path={RouteEnum.CustomerSettings}
            element={
              <RoleBasedRoute
                allowedRoles={[
                  RoleEnum.ROOT,
                  RoleEnum.CC_OPS_ADMIN,
                  RoleEnum.CC_OPS,
                  RoleEnum.CC_SALES,
                  RoleEnum.PARTNER_ADMIN,
                  RoleEnum.CUSTOMER_ADMIN,
                  RoleEnum.CUSTOMER_OPERATOR_FOREMAN,
                  RoleEnum.PARTNER_USER,
                  RoleEnum.CUSTOMER_OPS_TEAM,
                  RoleEnum.CUSTOMER_HSE,
                  RoleEnum.CUSTOMER_SECURITY,
                ]}
              >
                <CustomerSettingsPage />
              </RoleBasedRoute>
            }
          >
            <Route path=":tabId" element={<CustomerSettingsPage />} />
          </Route>

          <Route
            path={`${RouteEnum.CustomerSettings}/${RouteEnum.CreateAlert}`}
            element={
              <RoleBasedRoute
                allowedRoles={[
                  RoleEnum.ROOT,
                  RoleEnum.CC_OPS_ADMIN,
                  RoleEnum.CC_OPS,
                  RoleEnum.CC_SALES,
                  RoleEnum.PARTNER_ADMIN,
                  RoleEnum.CUSTOMER_ADMIN,
                  RoleEnum.CUSTOMER_OPERATOR_FOREMAN,
                  RoleEnum.PARTNER_USER,
                  RoleEnum.CUSTOMER_OPS_TEAM,
                  RoleEnum.CUSTOMER_HSE,
                  RoleEnum.CUSTOMER_SECURITY,
                ]}
              >
                <CreateAlertPage />
              </RoleBasedRoute>
            }
          />

          <Route
            path="/manage-locations/create"
            element={
              <RoleBasedRoute
                allowedRoles={[
                  RoleEnum.ROOT,
                  RoleEnum.CC_OPS_ADMIN,
                  RoleEnum.CC_OPS,
                  RoleEnum.CC_SALES,
                  RoleEnum.PARTNER_ADMIN,
                  RoleEnum.CUSTOMER_ADMIN,
                ]}
              >
                <CreateLocationPage />
              </RoleBasedRoute>
            }
          />
          <Route
            path="/manage-locations/edit/:locationId"
            element={
              <RoleBasedRoute
                allowedRoles={[
                  RoleEnum.ROOT,
                  RoleEnum.CC_OPS_ADMIN,
                  RoleEnum.CC_OPS,
                  RoleEnum.CC_SALES,
                  RoleEnum.PARTNER_ADMIN,
                  RoleEnum.CUSTOMER_ADMIN,
                ]}
              >
                <EditLocationPage />
              </RoleBasedRoute>
            }
          />

          <Route
            path="/system-settings"
            element={
              <RoleBasedRoute
                allowedRoles={[
                  RoleEnum.ROOT,
                  RoleEnum.CC_OPS_ADMIN,
                  RoleEnum.CC_OPS,
                  RoleEnum.CC_SALES,
                  RoleEnum.PARTNER_ADMIN,
                  RoleEnum.CUSTOMER_ADMIN,
                  RoleEnum.CUSTOMER_OPERATOR_FOREMAN,
                  RoleEnum.PARTNER_USER,
                  RoleEnum.CUSTOMER_OPS_TEAM,
                  RoleEnum.CUSTOMER_HSE,
                  RoleEnum.CUSTOMER_SECURITY,
                ]}
              >
                <SystemSettingsPage />
              </RoleBasedRoute>
            }
          >
            <Route path=":tabId" element={<SystemSettingsPage />} />
          </Route>

          <Route
            path={RouteEnum.IntegrationsAzure}
            element={
              <RoleBasedRoute
                allowedRoles={[
                  RoleEnum.ROOT,
                  RoleEnum.CC_OPS_ADMIN,
                  RoleEnum.CC_OPS,
                  RoleEnum.CC_SALES,
                  RoleEnum.PARTNER_ADMIN,
                  RoleEnum.CUSTOMER_ADMIN,
                ]}
              >
                <AzureIntegrationPage />
              </RoleBasedRoute>
            }
          />

          <Route
            path={RouteEnum.IntegrationsS3}
            element={
              <RoleBasedRoute
                allowedRoles={[
                  RoleEnum.ROOT,
                  RoleEnum.CC_OPS_ADMIN,
                  RoleEnum.CC_OPS,
                  RoleEnum.CC_SALES,
                  RoleEnum.PARTNER_ADMIN,
                  RoleEnum.CUSTOMER_ADMIN,
                ]}
              >
                <S3IntegrationPage />
              </RoleBasedRoute>
            }
          />
          <Route
            path={RouteEnum.IntegrationsEdge}
            element={
              <RoleBasedRoute
                allowedRoles={[
                  RoleEnum.ROOT,
                  RoleEnum.CC_OPS_ADMIN,
                  RoleEnum.CC_OPS,
                  RoleEnum.CC_SALES,
                  RoleEnum.PARTNER_ADMIN,
                  RoleEnum.CUSTOMER_ADMIN,
                ]}
              >
                <EdgeIntegrationPage />
              </RoleBasedRoute>
            }
          />

          <Route
            path="/email-notifications"
            element={
              <RoleBasedRoute allowedRoles={[RoleEnum.ROOT]}>
                <EmailNotificationsPage />
              </RoleBasedRoute>
            }
          />

          <Route
            path={`${RouteEnum.RecentActivitiesPlatesManagement}`}
            element={
              <RoleBasedRoute
                allowedRoles={[
                  RoleEnum.ROOT,
                  RoleEnum.PARTNER_ADMIN,
                  RoleEnum.CUSTOMER_ADMIN,
                  RoleEnum.CUSTOMER_OPERATOR_FOREMAN,
                  RoleEnum.CC_OPS_ADMIN,
                  RoleEnum.CC_OPS,
                  RoleEnum.CC_SALES,
                ]}
              >
                <PlatesManagement />
              </RoleBasedRoute>
            }
          />

          <Route
            path={`${RouteEnum.RecentActivitiesPlatesManagementPauseAlerts}`}
            element={
              <RoleBasedRoute
                allowedRoles={[
                  RoleEnum.ROOT,
                  RoleEnum.PARTNER_ADMIN,
                  RoleEnum.CUSTOMER_ADMIN,
                  RoleEnum.CUSTOMER_OPERATOR_FOREMAN,
                  RoleEnum.CUSTOMER_OPS_TEAM,
                  RoleEnum.CUSTOMER_HSE,
                  RoleEnum.CUSTOMER_SECURITY,
                ]}
              >
                <PauseAlertsPage />
              </RoleBasedRoute>
            }
          />

          <Route
            path={RouteEnum.RecentActivitiesPlatesManagementCreatePlateNumber}
            element={
              <RoleBasedRoute
                allowedRoles={[
                  RoleEnum.ROOT,
                  RoleEnum.PARTNER_ADMIN,
                  RoleEnum.CUSTOMER_ADMIN,
                  RoleEnum.CUSTOMER_OPERATOR_FOREMAN,
                  RoleEnum.CUSTOMER_OPS_TEAM,
                  RoleEnum.CUSTOMER_HSE,
                  RoleEnum.CUSTOMER_SECURITY,
                  RoleEnum.CC_PROCTORS,
                ]}
              >
                <CreatePlateNumberPage />
              </RoleBasedRoute>
            }
          />
          <Route
            path={RouteEnum.SystemSettingsPlatesManagementCreatePlateNumber}
            element={
              <RoleBasedRoute
                allowedRoles={[
                  RoleEnum.ROOT,

                  RoleEnum.CUSTOMER_OPERATOR_FOREMAN,
                  RoleEnum.CUSTOMER_OPS_TEAM,
                  RoleEnum.CUSTOMER_HSE,
                  RoleEnum.CUSTOMER_SECURITY,
                ]}
              >
                <CreatePlateNumberPageSystemSettings />
              </RoleBasedRoute>
            }
          />

          <Route
            path={RouteEnum.RecentActivitiesPlatesManagementEditPlateNumber}
            element={
              <RoleBasedRoute
                allowedRoles={[
                  RoleEnum.ROOT,
                  RoleEnum.PARTNER_ADMIN,
                  RoleEnum.CUSTOMER_ADMIN,

                  RoleEnum.CUSTOMER_OPERATOR_FOREMAN,
                  RoleEnum.CUSTOMER_OPS_TEAM,
                  RoleEnum.CUSTOMER_HSE,
                  RoleEnum.CUSTOMER_SECURITY,
                ]}
              >
                <EditPlateNumberPage />
              </RoleBasedRoute>
            }
          />

          <Route
            path={RouteEnum.RecentActivitiesPlatesManagementEditCategory}
            element={
              <RoleBasedRoute
                allowedRoles={[
                  RoleEnum.ROOT,
                  RoleEnum.PARTNER_ADMIN,
                  RoleEnum.CUSTOMER_ADMIN,
                  RoleEnum.CUSTOMER_OPERATOR_FOREMAN,
                  RoleEnum.CUSTOMER_OPS_TEAM,
                  RoleEnum.CUSTOMER_HSE,
                  RoleEnum.CUSTOMER_SECURITY,
                ]}
              >
                <EditCategoryPage />
              </RoleBasedRoute>
            }
          />
        </Route>

        <Route element={<CustomerLayout />}>
          <Route
            path="/sensory"
            element={
              <RoleBasedRoute
                allowedRoles={[
                  RoleEnum.CUSTOMER_OPERATOR_FOREMAN,
                  RoleEnum.PARTNER_USER,
                  RoleEnum.CUSTOMER_OPS_TEAM,
                  RoleEnum.CUSTOMER_HSE,
                  RoleEnum.CUSTOMER_SECURITY,
                  RoleEnum.VERIFIER,
                ]}
              >
                <DataHubPage />
              </RoleBasedRoute>
            }
          >
            <Route
              path={RouteEnum.SensoryRecentActivities}
              index
              element={
                <RoleBasedRoute
                  allowedRoles={[
                    RoleEnum.CUSTOMER_OPERATOR_FOREMAN,
                    RoleEnum.PARTNER_USER,
                    RoleEnum.CUSTOMER_OPS_TEAM,
                    RoleEnum.CUSTOMER_HSE,
                    RoleEnum.CUSTOMER_SECURITY,
                    RoleEnum.VERIFIER,
                  ]}
                >
                  <DataHubDashboard />
                </RoleBasedRoute>
              }
            />
            <Route
              path={`${RouteEnum.SensoryRecentActivities}/:model`}
              element={
                <RoleBasedRoute
                  allowedRoles={[
                    RoleEnum.CUSTOMER_OPERATOR_FOREMAN,
                    RoleEnum.PARTNER_USER,
                    RoleEnum.CUSTOMER_OPS_TEAM,
                    RoleEnum.CUSTOMER_HSE,
                    RoleEnum.CUSTOMER_SECURITY,
                    RoleEnum.VERIFIER,
                  ]}
                >
                  <DataHubPageContent />
                </RoleBasedRoute>
              }
            />
            <Route
              path="/sensory/live-view"
              element={
                <RoleBasedRoute
                  allowedRoles={[
                    RoleEnum.CUSTOMER_OPERATOR_FOREMAN,
                    RoleEnum.CUSTOMER_OPS_TEAM,
                    RoleEnum.PARTNER_USER,
                    RoleEnum.CUSTOMER_HSE,
                    RoleEnum.CUSTOMER_SECURITY,
                  ]}
                >
                  <DataHubLiveViewPageContent />
                </RoleBasedRoute>
              }
            />
            <Route
              path="/sensory/live-view/zones-list"
              element={
                <RoleBasedRoute
                  allowedRoles={[
                    RoleEnum.ROOT,
                    RoleEnum.CC_OPS_ADMIN,
                    RoleEnum.CC_OPS,
                    RoleEnum.CC_SALES,
                    RoleEnum.PARTNER_ADMIN,
                    RoleEnum.CUSTOMER_ADMIN,
                    RoleEnum.CUSTOMER_OPERATOR_FOREMAN,
                    RoleEnum.PARTNER_USER,
                    RoleEnum.CUSTOMER_OPS_TEAM,
                    RoleEnum.CUSTOMER_HSE,
                    RoleEnum.CUSTOMER_SECURITY,
                  ]}
                >
                  <ZoneList />
                </RoleBasedRoute>
              }
            />
            <Route
              path="/sensory/deployment-health"
              element={
                <RoleBasedRoute
                  allowedRoles={[
                    RoleEnum.CUSTOMER_OPERATOR_FOREMAN,
                    RoleEnum.PARTNER_USER,
                  ]}
                >
                  <DeploymentHealthPage />
                </RoleBasedRoute>
              }
            />
            <Route
              path="/sensory/statistics"
              element={
                <RoleBasedRoute
                  allowedRoles={[
                    RoleEnum.CUSTOMER_OPERATOR_FOREMAN,
                    RoleEnum.PARTNER_USER,
                  ]}
                >
                  <StatisticsPage />
                </RoleBasedRoute>
              }
            />
          </Route>

          <Route
            path="/sensory/system-settings"
            element={
              <RoleBasedRoute
                allowedRoles={[
                  RoleEnum.ROOT,
                  RoleEnum.CC_OPS_ADMIN,
                  RoleEnum.CC_OPS,
                  RoleEnum.CC_SALES,
                  RoleEnum.PARTNER_ADMIN,
                  RoleEnum.CUSTOMER_ADMIN,
                  RoleEnum.CUSTOMER_OPERATOR_FOREMAN,
                  RoleEnum.PARTNER_USER,
                  RoleEnum.CUSTOMER_OPS_TEAM,
                  RoleEnum.CUSTOMER_HSE,
                  RoleEnum.CUSTOMER_SECURITY,
                ]}
              >
                <SystemSettingsPage />
              </RoleBasedRoute>
            }
          >
            <Route path=":tabId" element={<SystemSettingsPage />} />
          </Route>

          <Route
            path={`${RouteEnum.SensoryEditLocation}:locationId`}
            element={
              <RoleBasedRoute
                allowedRoles={[
                  RoleEnum.CUSTOMER_OPERATOR_FOREMAN,
                  RoleEnum.PARTNER_USER,
                  RoleEnum.CUSTOMER_OPS_TEAM,
                  RoleEnum.CUSTOMER_HSE,
                  RoleEnum.CUSTOMER_SECURITY,
                  RoleEnum.CC_PROCTORS,
                ]}
              >
                <EditLocationPage />
              </RoleBasedRoute>
            }
          />

          <Route
            path={RouteEnum.SensoryIntegrationsAzure}
            element={
              <RoleBasedRoute
                allowedRoles={[
                  RoleEnum.CUSTOMER_OPERATOR_FOREMAN,
                  RoleEnum.PARTNER_USER,
                  RoleEnum.CUSTOMER_OPS_TEAM,
                  RoleEnum.CUSTOMER_HSE,
                  RoleEnum.CUSTOMER_SECURITY,
                  RoleEnum.CC_PROCTORS,
                ]}
              >
                <AzureIntegrationPage />
              </RoleBasedRoute>
            }
          />

          <Route
            path={RouteEnum.SensoryIntegrationsS3}
            element={
              <RoleBasedRoute
                allowedRoles={[
                  RoleEnum.CUSTOMER_OPERATOR_FOREMAN,
                  RoleEnum.PARTNER_USER,
                  RoleEnum.CUSTOMER_OPS_TEAM,
                  RoleEnum.CUSTOMER_HSE,
                  RoleEnum.CUSTOMER_SECURITY,
                  RoleEnum.CC_PROCTORS,
                ]}
              >
                <S3IntegrationPage />
              </RoleBasedRoute>
            }
          />
          <Route
            path={RouteEnum.SensoryIntegrationsEdge}
            element={
              <RoleBasedRoute
                allowedRoles={[
                  RoleEnum.CUSTOMER_OPERATOR_FOREMAN,
                  RoleEnum.PARTNER_USER,
                  RoleEnum.CUSTOMER_OPS_TEAM,
                  RoleEnum.CUSTOMER_HSE,
                  RoleEnum.CUSTOMER_SECURITY,
                  RoleEnum.CC_PROCTORS,
                ]}
              >
                <EdgeIntegrationPage />
              </RoleBasedRoute>
            }
          />

          <Route
            path={`${RouteEnum.SensoryRecentActivitiesPlatesManagement}`}
            element={
              <RoleBasedRoute
                allowedRoles={[
                  RoleEnum.CUSTOMER_OPERATOR_FOREMAN,
                  RoleEnum.CUSTOMER_OPS_TEAM,
                  RoleEnum.CUSTOMER_HSE,
                  RoleEnum.CUSTOMER_SECURITY,
                ]}
              >
                <PlatesManagement />
              </RoleBasedRoute>
            }
          />

          <Route
            path={
              RouteEnum.SensoryRecentActivitiesPlatesManagementCreatePlateNumber
            }
            element={
              <RoleBasedRoute
                allowedRoles={[
                  RoleEnum.ROOT,
                  RoleEnum.CUSTOMER_OPERATOR_FOREMAN,
                  RoleEnum.CUSTOMER_OPS_TEAM,
                  RoleEnum.CUSTOMER_HSE,
                  RoleEnum.CUSTOMER_SECURITY,
                  RoleEnum.CC_PROCTORS,
                ]}
              >
                <CreatePlateNumberPage />
              </RoleBasedRoute>
            }
          />
          <Route
            path={
              RouteEnum.SensorySystemSettingsPlatesManagementCreatePlateNumber
            }
            element={
              <RoleBasedRoute
                allowedRoles={[
                  RoleEnum.CUSTOMER_OPERATOR_FOREMAN,
                  RoleEnum.CUSTOMER_OPS_TEAM,
                  RoleEnum.CUSTOMER_HSE,
                  RoleEnum.CUSTOMER_SECURITY,
                ]}
              >
                <CreatePlateNumberPageSystemSettings />
              </RoleBasedRoute>
            }
          />

          <Route
            path={
              RouteEnum.SensoryRecentActivitiesPlatesManagementEditPlateNumber
            }
            element={
              <RoleBasedRoute
                allowedRoles={[
                  RoleEnum.CUSTOMER_OPERATOR_FOREMAN,
                  RoleEnum.CUSTOMER_OPS_TEAM,
                  RoleEnum.CUSTOMER_HSE,
                  RoleEnum.CUSTOMER_SECURITY,
                ]}
              >
                <EditPlateNumberPage />
              </RoleBasedRoute>
            }
          />

          <Route
            path={RouteEnum.SensoryRecentActivitiesPlatesManagementEditCategory}
            element={
              <RoleBasedRoute
                allowedRoles={[
                  RoleEnum.CUSTOMER_OPERATOR_FOREMAN,
                  RoleEnum.CUSTOMER_OPS_TEAM,
                  RoleEnum.CUSTOMER_HSE,
                  RoleEnum.CUSTOMER_SECURITY,
                ]}
              >
                <EditCategoryPage />
              </RoleBasedRoute>
            }
          />

          <Route
            path={`${RouteEnum.SensorySystemSettings}/${RouteEnum.CreateAlert}`}
            element={
              <RoleBasedRoute
                allowedRoles={[
                  RoleEnum.CUSTOMER_OPERATOR_FOREMAN,
                  RoleEnum.PARTNER_USER,
                  RoleEnum.CUSTOMER_OPS_TEAM,
                  RoleEnum.CUSTOMER_HSE,
                  RoleEnum.CUSTOMER_SECURITY,
                ]}
              >
                <CreateAlertPage />
              </RoleBasedRoute>
            }
          />
        </Route>

        <Route
          element={
            <RoleBasedRoute
              allowedRoles={[
                RoleEnum.ROOT,
                RoleEnum.CC_OPS_ADMIN,
                RoleEnum.CC_PROCTORS,
              ]}
            >
              <HumanValidatorLayout />
            </RoleBasedRoute>
          }
        >
          <Route
            path="/human-validator"
            element={
              <RoleBasedRoute
                allowedRoles={[
                  RoleEnum.ROOT,
                  RoleEnum.CC_OPS_ADMIN,
                  RoleEnum.CC_PROCTORS,
                ]}
              >
                <HumanValidatorPage />
              </RoleBasedRoute>
            }
          />
          <Route
            path="/hv-review"
            element={
              <RoleBasedRoute
                allowedRoles={[RoleEnum.ROOT, RoleEnum.CC_OPS_ADMIN]}
              >
                <HvReviewPage />
              </RoleBasedRoute>
            }
          />
        </Route>

        <Route
          element={
            <LeakFinderRoleBasedRoute
              allowedRoles={[
                RoleEnum.ROOT,
                RoleEnum.LEAKFINDER_DIRECTOR,
                RoleEnum.CC_SALES,
                RoleEnum.CUSTOMER_OPERATOR_FOREMAN,
              ]}
            >
              <LeakFinderLayout />
            </LeakFinderRoleBasedRoute>
          }
        >
          <Route
            path={RouteEnum.LeakFinderClientUploads}
            element={
              <LeakFinderRoleBasedRoute
                allowedRoles={[
                  RoleEnum.ROOT,
                  RoleEnum.LEAKFINDER_DIRECTOR,
                  RoleEnum.CC_SALES,
                  RoleEnum.CUSTOMER_OPERATOR_FOREMAN,
                ]}
              >
                <ClientUploadsPage />
              </LeakFinderRoleBasedRoute>
            }
          />
          <Route
            path={RouteEnum.LeakFinderClientUploadsCreate}
            element={
              <LeakFinderRoleBasedRoute
                allowedRoles={[
                  RoleEnum.ROOT,
                  RoleEnum.CUSTOMER_OPERATOR_FOREMAN,
                  RoleEnum.LEAKFINDER_DIRECTOR,
                  RoleEnum.CC_SALES,
                ]}
              >
                <AddNewClientPage />
              </LeakFinderRoleBasedRoute>
            }
          />
          <Route
            path={RouteEnum.LeakFinderClientUploadsEditClient}
            element={
              <LeakFinderRoleBasedRoute
                allowedRoles={[
                  RoleEnum.ROOT,
                  RoleEnum.CUSTOMER_OPERATOR_FOREMAN,
                  RoleEnum.LEAKFINDER_DIRECTOR,
                  RoleEnum.CC_SALES,
                ]}
              >
                <EditClientPage />
              </LeakFinderRoleBasedRoute>
            }
          />
          <Route
            path={RouteEnum.LeakFinderUploadNewVideoGroupPage}
            element={
              <LeakFinderRoleBasedRoute
                allowedRoles={[
                  RoleEnum.ROOT,
                  RoleEnum.LEAKFINDER_DIRECTOR,
                  RoleEnum.CC_SALES,
                  RoleEnum.CUSTOMER_OPERATOR_FOREMAN,
                ]}
              >
                <UploadVideoGroupPage />
              </LeakFinderRoleBasedRoute>
            }
          />

          <Route
            path={RouteEnum.LeakFinderClientUploadsEditGroup}
            element={
              <LeakFinderRoleBasedRoute
                allowedRoles={[
                  RoleEnum.ROOT,
                  RoleEnum.LEAKFINDER_DIRECTOR,
                  RoleEnum.CUSTOMER_OPERATOR_FOREMAN,
                ]}
              >
                <EditGroupPage />
              </LeakFinderRoleBasedRoute>
            }
          />

          <Route
            path={RouteEnum.LeakFinderUserPreferences}
            element={
              <LeakFinderRoleBasedRoute
                allowedRoles={[
                  RoleEnum.ROOT,
                  RoleEnum.LEAKFINDER_DIRECTOR,
                  RoleEnum.CUSTOMER_OPERATOR_FOREMAN,
                ]}
              >
                <LeakFinderUserPreferencesPage />
              </LeakFinderRoleBasedRoute>
            }
          />
        </Route>

        <Route
          path="old"
          element={
            <RoleBasedRoute allowedRoles={[RoleEnum.ROOT]}>
              <AppOld />
            </RoleBasedRoute>
          }
        />

        <Route
          path="*"
          element={
            <main style={{ padding: "1em" }}>
              <p>There&apos;s nothing here!</p>
              <button
                onClick={(): void => {
                  window.location.href = "/";
                }}
              >
                Go back to home page
              </button>
            </main>
          }
        />
      </Routes>
    </Suspense>
  );
};

export default AppRoutes;
